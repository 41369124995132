angular.module("aerosApp")
	.controller("UsersCtrl",
	[ "$scope", "aerosApi", "$stateParams", "userInvitationModal", "addRogueUnitModal", "$uibModal", "Notification",
        "$translate", "allRoles", "PageService", "instructions", "routesConstant",
      function($scope, aerosApi, $stateParams, userInvitationModal, addRogueUnitModal, $uibModal, Notification,
               $translate, allRoles, PageService, instructions, routesConstant) {
		$scope.$watch("commonReady", function (newVal) {
			if (!newVal) return;
			if (!$scope.allow['viewUsers']) return $scope.reject();
			$scope.messages = {
				"deleteSuccess": "User deleted."
			};
			_.each($scope.messages, function (message,key) {
				$translate(message).then(function (translation) {
					$scope.messages[key]= translation;
				});
			});

			angular.extend($scope, {
				toggleSorting:toggleSorting,
				sortingFieldStatus:sortingFieldStatus
			});

            (function setAllRoles() {
                $scope.roleNameById = {};
                $scope.roleRankById = {};
                $scope.rolesTooltip = '| ';
                _.each(allRoles.orgRoles, function (role) {
                    $scope.roleNameById[role.id] = role.name;
                    $scope.roleRankById[role.id] = role.rank;
                    $scope.rolesTooltip += role.name + ": " + role.description + " | ";
                });
                $scope.bestRankedId = function (roles) {
                    var bestRank = 999;
                    var bestRankedId = "";
                    _.each(roles, function(role) {
                        var roleLower = role.role.toLowerCase();
                        var roleRank = $scope.roleRankById[role.role.toLowerCase()];
                        if (roleRank < bestRank) {
                            bestRank = roleRank;
                            bestRankedId = roleLower;
                        }
                    });
                    return bestRankedId;
                };
                var highestRank = _.min(_.filter(allRoles.orgRoles, function(role) {
                    return _.includes($scope.roles, role.id);
                }), function (roleObj) {
                    return roleObj.rank;
                }).rank;
                var editableRoleIds = _.map(_.filter(allRoles.orgRoles, function (role) {
                    return role.rank >= highestRank;
                }), "id");
                $scope.availableRoles = _.filter(allRoles.orgRoles, function (role) {
                    return _.includes(editableRoleIds, role.id);
                });

                // lowest available role
                $scope.selectedRoles = _.take(_.sortBy( $scope.availableRoles, function (role) {return role.rank * -1;}));
            })();

			$scope.canEdit = function (roleId) {
				return _.includes(editableRoleIds, roleId);
			};
			$scope.showRoleSelection = false;
			$scope.me = $scope.$parent.user;
			$scope.license = $scope.$parent.organization.license;

			(function setSortDefaults(){
				$scope.sortFieldName = 'lastName';
				$scope.isReverse = false;
			})();


			function toggleSorting(key) {
				if ($scope.sortFieldName == key) {
					$scope.isReverse = !$scope.isReverse;
				} else {
					$scope.sortFieldName = key;
					$scope.isReverse = false;
				}
			}

			function sortingFieldStatus(key) {
				if ($scope.sortFieldName == key) {
					return $scope.isReverse ? "up" : "down";
				}

				return "none";
			}


			function loadOrganizationUsers () {
				aerosApi.loadOrganizationUserInfo($scope.$parent.organization.id).then(function (rsp){
					console.log("loadOrganizationUserInfo");
					console.log(rsp.data);
					$scope.me.openSeats = rsp.data.user.openSeats;
					$scope.$parent.loadOrganizationUsers($scope.$parent.organization.id).then(function (rsp) {
						var users = rsp.data.users;
						var orgUsers = [];
						_.each(users, function (user) {
							user.searchIndex = user.firstName + " " + user.lastName + " " + user.email + " "
								+ $scope.roleNameById[$scope.bestRankedId(user.roles.aerosRoles)] + " " + user.authStatus;

							Object.defineProperty(user, "fullName", {
								get : function get() {
									return user.lastName + ", " + user.firstName;
								}
							})
						});
						_.each(users, function(user) {
							if (!user.orgAdministrator && !(user.status == "Suspended")) {
								orgUsers.push(user);
							}
						});
						$scope.users = orgUsers;
					});
				});
			}

			$scope.contact = {
				"Email": "aeros.support@aflglobal.com",
				"Phone": "1-800-321-5298: press option 3"
			};
			$scope.showContact = function () {
				$uibModal.open({
					templateUrl: 'contactInfoModal.html',
					resolve: {
						contact: function () {
							return $scope.contact;
						}
					},
					controller: ["$scope", "contact", function($scope, contact){
						$scope.contact = contact;
					}]
				});
			};

			$scope.addNewRogueUnit = function() {
				addRogueUnitModal.open().then(function(newRogue) {
					$scope.rogueUnits.push(newRogue);
				});
			};

			$scope.hasOpenSeats = function() {
				return ($scope.license == 'Basic' || ($scope.me.openSeats !== undefined && $scope.me.openSeats > 0));
			};

			$scope.inviteNewUser = function() {
				var availableLicenses=	[
                  	 {
                  		 id: $scope.user.licenseKey,
                  		 label: $scope.user.licenseKey}
                  	 ];
				var availableRoles= [
				                     {rank: 3, id: "user", name: "Basic User", description: "May be assigned to projects"}
				                     ];
				var selectedRoles=[availableRoles[0]];


				userInvitationModal.open('Invite a new user to "' + $scope.organization.name + '"',
                    false, availableLicenses, $scope.organization.id, availableRoles, selectedRoles,
                    false)
                    .then(function (user) {
                        aerosApi.inviteUser(user, user.licenseKey)
                            .success(function (data) {
                                Notification.success("User added to organization");
                                loadOrganizationUsers($scope.organization.id);
                            })
                            .error(function (err) {
                                Notification.error("User not invited. " + err.message);
                            })

                    });
			};

			$scope.deleteUser = function(user) {
				if (!$scope.allow["deleteUsers"]) return;
				$uibModal.open({
					resolve: {
						user: function () {
							return user;
						}
					},
					templateUrl: 'confirmDeleteModal.html',
					controller: ["$scope", "user", function($scope, user){
						$scope.fullName = user.firstName + " " + user.lastName;
					}]
			    }).result.then(function () {
			    	aerosApi.deleteUser(user.username, $scope.organization.id)
			    		.success(function (data) {
    						loadOrganizationUsers($scope.organization.id);
    						Notification.success($scope.messages['deleteSuccess']);
                        })
                        .error(function (err) {
                            Notification.error("User not Deleted. " + err.message);
                        })
			    });
			};

			$scope.editUserRole = function (user) {
				var passUser = {
					email: user.email,
					firstName: user.firstName,
					lastName: user.lastName,
					organizationId: $scope.organization.id,
					roles: _.map(user.roles.aerosRoles, "role") || []
				};
				userInvitationModal.open('Edit role of ' + user.firstName + ' ' + user.lastName + ' in "' + $scope.organization.name + '"',
						true,
						$scope.organization.id,
						$scope.availableRoles,
						_.map(user.roles.aerosRoles, function (role) { // selectedRoles
						    return _.find($scope.availableRoles, {id: role.role});
						}),
						true, false, // showRoleSelection, requireOneRole
						passUser
				).then(function(user) {
					aerosApi.editUser(user).then(function(data) {
						loadOrganizationUsers($scope.organization.id);
					});
				});
			};

			$scope.userInvitation = function(username, orgId) {
				aerosApi.userInvitationEmail(username, orgId).then(
                    function (response) {
                        Notification.success("A user activation email has been sent to " + username);
                    },
                    function (error) {
                        Notification.error("Error encountered sending user activation email to " + username);
                    })
            };
			$scope.userReset = function(username) {
				aerosApi.sendResetEmail(username).then(
                    function (response) {
                        Notification.success("A reset-password email has been sent to " + username);
                    },
                    function (error) {
                        Notification.error("Error encountered sending reset-password email to " + username);
                    })
            };

            PageService.setTitle('Organization Users');
			loadOrganizationUsers();

            function findInstructionByAction(action) {
                return instructions[routesConstant.CUSTOMER.ORGANIZATION_USERS.stateName]
                    .actions.find(function (element) {
                        return element.action && element.action.toLowerCase() === action.toLowerCase();
                    });
            }

            (function setActions() {

                $scope.actions = {
                    "New User": {
                        label: function label(users) {
                            return findInstructionByAction("New User").instructions;
                        },
                        icon: function icon(users) {
                            return 'fa-' + findInstructionByAction("New User").icon;
                        },
                        if: function (users) {
                            return true;
                        },
                        disabled: function (users) {
                            return !$scope.hasOpenSeats();
                        },
                        action: function (users) {
                            return $scope.inviteNewUser();
                        }
                    },
                    "Details": {
                        label: function label(users) {
                            return findInstructionByAction("Details").instructions;
                        },
                        icon: function icon(users) {
                            return 'fa-' + findInstructionByAction("Details").icon;
                        },
                        if: function (users) {
                            return users && users.length > 0;
                        },
                        disabled: function (users) {
                            return false;
                        },
                        action: function (users) {
                        }
                    }
                };

                $scope.userActions = [{
                    label: function label(user) {
                        return findInstructionByAction("Reset Password").instructions;
                    },
                    icon: function icon(user) {
                        return 'fa-' + findInstructionByAction("Reset Password").icon;
                    },
                    if: function (user) {
                        return user.authStatus === 'Active' && $scope.allow.activateUsers;
                    },
                    disabled: function (user) {
                        return false;
                    },
                    action: function (user) {
                        return $scope.userReset(user.username);
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Activate").instructions;
                    },
                    icon: function icon(user) {
                        return 'fa-' + findInstructionByAction("Activate").icon;
                    },
                    if: function (user) {
                        return $scope.allow.activateUsers;
                    },
                    disabled: function (user) {
                        return false;
                    },
                    action: function (user) {
                        return $scope.userInvitation(user.username, $scope.organization.id);
                    }
                }, {
                    label: function label(project) {
                        return findInstructionByAction("Delete User").instructions;
                    },
                    icon: function icon(user) {
                        return 'fa-' + findInstructionByAction("Delete User").icon;
                    },
                    if: function (user) {
                        return $scope.allow.deleteUsers && user.username !== $scope.me.username;
                    },
                    disabled: function (user) {
                        return false;
                    },
                    action: function (user) {
                        return $scope.deleteUser(user);
                    }
                }]
            })();
		});
	}]);
